import React, { useEffect, createRef, useState } from 'react';
import ExplosionAnimation from './elements/explosion';
import Legend from './elements/legend';
import CircleImage from './elements/circleImage';
import img1 from 'src/assets/images/chapter_3/sub_5/faces/map-9_1.png';
import img2 from 'src/assets/images/chapter_3/sub_5/faces/map-9_2.png';

import { SwitchTransition, CSSTransition } from "react-transition-group";

import { MainAnimation, EmptyWrapper, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation1 from './animations/3b1.json';
import animation2 from './animations/3b2.json';
import animation3 from './animations/3b3.json';

const NAMES_1 = {
    PL: {
        first: 'Władysław',
        last: 'Belina-Prażmowski'
    },
    RU: {
        first: 'Владислава',
        last: 'Белины-Пражмовского'
    },
    UA: {
        first: 'Владислава',
        last: 'Беліни- Пражмовського'
    }
}

const NAMES_2 = {
    PL: {
        first: 'Edward',
        last: 'Rydz-Śmigły'
    },
    RU: {
        first: 'Эдварда',
        last: 'Рыдз-Смиглы'
    },
    UA: {
        first: 'Едварда',
        last: 'Ридз-Сміглого'
    }
}

const Map1 = ({ language }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation1,
        });

        return () => anim.destroy();

    }, []);

    return (
        <EmptyWrapper>
            <MainAnimation ref={animationContainer}
                top={1.3} right={-4} />
            <CircleImage top={69} right={language === 'UA' || language === 'RU' ? 28 : 32} src={img1} name={NAMES_1[language] ? NAMES_1[language].first : NAMES_1['PL'].first} lastName={NAMES_1[language] ? NAMES_1[language].last : NAMES_1['PL'].last} />
            <ExplosionAnimation top={60} right={62} />
        </EmptyWrapper>
    )
}

const Map2 = ({ language }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation2,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <MainAnimation ref={animationContainer}
                top={1.3} right={-4} />
            <ExplosionAnimation top={21} right={58} />
            <ExplosionAnimation top={19} right={55} />
            <CircleImage top={58} right={language === 'UA' || language === 'RU' ? 57 : 59} src={img1} name={NAMES_1[language] ? NAMES_1[language].first : NAMES_1['PL'].first} lastName={NAMES_1[language] ? NAMES_1[language].last : NAMES_1['PL'].last} />
        </EmptyWrapper>
    )
}

const Map3 = ({ language }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation3,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <MainAnimation ref={animationContainer}
                top={1.3} right={-4} />
            <ExplosionAnimation top={21} right={58} />
            <ExplosionAnimation top={19} right={55} />
            <ExplosionAnimation top={22} right={63.6} />
            <ExplosionAnimation top={20} right={67.5} />
            <CircleImage top={58} right={language === 'UA' || language === 'RU' ? 57 : 59} src={img1} name={NAMES_1[language] ? NAMES_1[language].first : NAMES_1['PL'].first} lastName={NAMES_1[language] ? NAMES_1[language].last : NAMES_1['PL'].last} />
            <CircleImage top={58} right={language === 'UA' || language === 'RU' ? 74 : 72} src={img2} name={NAMES_2[language] ? NAMES_2[language].first : NAMES_2['PL'].first} lastName={NAMES_2[language] ? NAMES_2[language].last : NAMES_2['PL'].last} />
        </EmptyWrapper>
    )
}

const Map = ({ language }) => {
    const [mapIndex, setMapIndex] = useState(0);
    const maps = [<Map1 language={language} />, <Map2 language={language} />, <Map3 language={language} />]

    useEffect(() => {
        const interval = setInterval(() => {
            mapIndex < maps.length - 1 ? setMapIndex(index => index + 1) : setMapIndex(0);
        }, 5500);

        return () => clearInterval(interval);
    }, [mapIndex]);


    return (
        <OpacityWrapper>
            <SwitchTransition>
                <CSSTransition
                    key={mapIndex}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='fade-animation'
                >
                    <EmptyWrapper>
                        {maps[mapIndex]}
                    </EmptyWrapper>
                </CSSTransition>
            </SwitchTransition>
            {/* <Legend /> */}
        </OpacityWrapper>
    )
};

export default Map;
