import React from 'react';
import img from 'src/assets/images/chapter_3/sub_5/faces/map-12.png';
import CircleImage from './elements/circleImage';
import { OpacityWrapper } from './styles';

const NAMES = {
    PL: 'Karlis Ulmanis',
    RU: 'Карлис Улманис',
    UA: 'Карліс Улманіс'
}


const Map = ({ language }) => {

    return (
        <OpacityWrapper>
            <CircleImage top={47} right={33} src={img} name={NAMES[language] ? NAMES[language] : NAMES['PL']} />
        </OpacityWrapper>
    )
};

export default Map;
