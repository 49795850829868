import React from 'react';
import { CircleImg, CircleImageWrapper, CircleImageTitle } from '../styles'



const CircleImage = ({ src, name, lastName, top, right, slim = false }) => {

    return (
        <CircleImageWrapper
            style={{
                top: `${top}%`,
                right: `${right}%`,
            }} >
            <CircleImg src={src} />
            {name && <CircleImageTitle slim={slim}>
                {name}
                {lastName &&
                    <>
                        <br />
                        {lastName}
                    </>
                }
            </CircleImageTitle>}
        </CircleImageWrapper>
    )
};

export default CircleImage;
