import styled from 'styled-components';

export const MarkerContainer = styled.div`
position: relative;
${({ positionLeft }) => positionLeft && `
    left: ${positionLeft};
 `}
  ${({ positionTop }) => positionTop && `
     top: ${positionTop};
  `}
  width: ${(props) => (props.customWidth ? `${props.customWidth}` : 'auto')};
 @media (min-width: 1600px) {
  width: ${(props) => (props.customWidth1600 ? `${props.customWidth1600}` : 'auto')};
 }
`;

export const MarkerWrapper = styled.a`
  z-index: 100;
  position: absolute;
  ${({ positionRight }) => positionRight && `
    right: ${positionRight};
 `}
  ${({ positionTop }) => positionTop && `
     top: ${positionTop};
  `}
  ${({ noclick }) => !noclick && `
    cursor: pointer;
  `}
`;

export const MarkerText = styled.span`
  position: relative;
  width: 50px;
  left: 1.5rem;
  font-size: .875rem;
  color: #fdfdfd;
  font-family: "Red Hat Display";
  letter-spacing: 0.56px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const DotIcon = styled.span`
  right: -3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid #de2831;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #de2831;
  }
`;
