import React from 'react';
import ExplosionAnimation from './elements/explosion';
import { OpacityWrapper } from './styles';

const Map = () => {
    return (
        <OpacityWrapper>
            <ExplosionAnimation top={36} right={26} />
            <ExplosionAnimation top={49} right={32} />
            <ExplosionAnimation top={61} right={25} />
        </OpacityWrapper>
    )
};

export default Map;
