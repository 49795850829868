import React, { useState, useEffect } from 'react';

import {
  Wrapper, Line, Dot, RedLine, RedDot, DotContainer,
  ArrowDot, Arrow, AnimatedHoverCircle, OnSlideChangeAnimation,
  DateArrow, DateContainer, TitleContainer, GradientContainer,
  ToolTip,
} from './styles';

const Timeline = ({
  handleIndex,
  dots,
  yearsArray,
  titleArray,
  activeIndex,
  setActiveIndex,
  translateX,
}) => {
  const [red, setRed] = useState(100 / (dots + 1));
  const [oldIndex, setOldIndex] = useState(0);
  const [madeAnimationRight, setAnimationRight] = useState(false);
  const [madeAnimationLeft, setAnimationLeft] = useState(false);
  const [clickedArrow, setClickedArrow] = useState(false);

  const timeline = React.createRef();

  const setNewPosition = (index, vector) => {
    if (index >= 0 && index <= dots) {
      if (vector === 'left') {
        setAnimationLeft(true);
      } else if (vector === 'right') {
        setAnimationRight(true);
      }
      if (!clickedArrow) {
        setClickedArrow(true);
      }
      const oneWidth = 100 / (dots + 1);
      const elementWidth = oneWidth * (index + 1);
      setRed(elementWidth);
      setOldIndex(activeIndex);
      setActiveIndex(index);
      handleIndex(index);
    }
  };

  useEffect(() => {
    setNewPosition(activeIndex);
  }, [activeIndex]);

  const onAnimationEndLeft = () => {
    setAnimationLeft(false);
  };

  const onAnimationEndRight = () => {
    setAnimationRight(false);
  };
  const dotArray = [];
  for (let i = 0; i < dots; i++) {
    dotArray.push(i);
  }
  const allDots = dotArray.map((dot, index) => {
    const oneWidth = 100 / (dots + 1);
    const leftPosition = oneWidth * (index + 1);

    const style = {
      position: 'absolute',
      left: `${leftPosition}%`,
      cursor: 'pointer',
    };
    let exception = null;
    yearsArray.forEach((item, i) => {
      if (item.index === index) {
        exception = item;
      }
    });
    let title = null;
    titleArray.forEach((item, i) => {
      if (item.index === index) {
        title = item;
      }
    });
    const titleStyle = {
      left: `${leftPosition + (0.5 * oneWidth)}%`,
      cursor: 'pointer',
    };
    const showTitle = title ? (
      <TitleContainer style={titleStyle} positionTop={title.position === 'top'} positionBottom={title.position === 'bottom'}>
        {' '}
        {title.text}
        {' '}
      </TitleContainer>
    ) : '';
    if (exception) {
      return (
        <div key={index}>
          {showTitle}
          <div style={style} onClick={() => { setNewPosition(index); }}>
            <DateArrow />
            <DateContainer>
              {exception.year}
            </DateContainer>
          </div>
        </div>
      );
    }
    return (
      <div key={index}>
        {showTitle}
        <DotContainer style={style} onClick={() => { setNewPosition(index); }}>
          <Dot />
          {(activeIndex >= index)
            && (
              <RedDot
                widthElement={activeIndex === index && oldIndex - activeIndex < 0}
                showAll={index <= activeIndex}
              />
            )}
        </DotContainer>
      </div>
    );
  });

  return (
    <Wrapper translateX={translateX}>
      <GradientContainer />
      <Line ref={timeline}>
        {(madeAnimationRight)
          && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />}
        <AnimatedHoverCircle />
        {(madeAnimationLeft)
          && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />}
        <AnimatedHoverCircle />
        <RedLine redWidth={red} />
        {allDots}
      </Line>
    </Wrapper>
  );
};

export default Timeline;
