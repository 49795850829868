import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import Quote from 'src/components/desktop/quote/quote';
import { OpacityWrapper } from './styles'

const Map = ({ quote, assets }) => {
    return (
        <OpacityWrapper>
            <ParallaxMordo additionalStyling={{ overflow: 'visible' }}>
                <Mordo
                    mainscene
                    move={{ x: -20, y: -10 }}
                    background={{ image: assets.paralax_back.childImageSharp.fluid }}
                />

                <Mordo

                    scenelayer={{ size: '90%', fromLeft: '40%' }}
                    scene={1}
                    move={{ x: 10, y: 20 }}
                    background={{ image: assets.paralax_front.childImageSharp.fluid }}
                    opacity={1}
                />
                <Mordo
                    flexi
                    scene={2}
                    move={{ x: -20, y: -10 }}
                >
                    <div style={{ marginLeft: '-25%', width: '100%' }}>
                        <Quote customTreshold={0.5} fontColor="white" quoteText={quote.body} quoteAuthor={quote.frontmatter.author} quoteAuthorRole={quote.frontmatter.authorInfo} />
                    </div>
                </Mordo>
            </ParallaxMordo>
        </OpacityWrapper>

    )
};

export default Map;
