import React, { useEffect, createRef } from 'react';
import { MainAnimation, CircleFlag, OpacityWrapper } from './styles';
import ExplosionAnimation from './elements/explosion';
import CircleImage from './elements/circleImage';
import Legend from './elements/legend';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/5d.json';
import img from 'src/assets/images/chapter_3/sub_5/faces/map-9_2.png';
import flagLotwa from 'src/assets/images/chapter_3/sub_5/_shared/flag-lotwa.svg';
import flagPoland from 'src/assets/images/chapter_3/sub_5/_shared/flag-poland.svg';

const NAMES = {
    PL: {
        first: 'Edward',
        last: 'Rydz-Śmigły'
    },
    RU: {
        first: 'Эдварда',
        last: 'Рыдз-Смиглы'
    },
    UA: {
        first: 'Едварда',
        last: 'Ридз-Сміглого'
    }
}

const Map = ({ language }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation,
        });
        return () => anim.destroy();
    }, []);

    return (
        <OpacityWrapper>
            <ExplosionAnimation top={30} right={55} />
            <ExplosionAnimation top={32} right={52} />
            <CircleImage top={39} right={62} src={img}
                name={NAMES[language] ? NAMES[language].first : NAMES['PL'].first} lastName={NAMES[language] ? NAMES[language].last : NAMES['PL'].last} />
            <CircleFlag top={33} right={64} src={flagLotwa} />
            <CircleFlag top={39} right={58} src={flagPoland} />
            <MainAnimation ref={animationContainer}
                top={-3} right={-2} />
            {/* <Legend legendType={1} /> */}

        </OpacityWrapper>
    )
};

export default Map;
