import React from 'react';
import { CannonImg } from '../styles'
import img from 'src/assets/images/chapter_3/sub_5/_shared/cannon.svg';



const Cannon = ({ top, right }) => {

    return (
        <>
            <CannonImg
                style={{
                    top: `${top}%`,
                    right: `${right}%`,
                }}
                src={img}>
            </CannonImg>
        </>
    )
};

export default Cannon;
