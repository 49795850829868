import React from 'react';
import ExplosionAnimation from './elements/explosion';
import CircleImage from './elements/circleImage';
import img from 'src/assets/images/chapter_3/sub_5/faces/map-3.png';
import { OpacityWrapper } from './styles';

const NAMES = {
    PL: 'Władysław Wejtko',
    RU: 'Владислава Вейтки',
    UA: 'Владислава Вейткі',
}

const Map = ({ language }) => {

    return (
        <OpacityWrapper>
            <ExplosionAnimation top={38} right={60.5} />
            <ExplosionAnimation top={56} right={64} />
            <ExplosionAnimation top={61.3} right={67.5} />
            <ExplosionAnimation top={56} right={57.7} />
            <ExplosionAnimation top={65} right={56.4} />
            <CircleImage top={42} right={52} src={img} name={NAMES[language] ? NAMES[language] : NAMES['PL']} />
        </OpacityWrapper>
    )
};

export default Map;
