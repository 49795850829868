import React from 'react';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { isMobile } from 'react-device-detect';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { MarkerWrapper, MarkerText, DotIcon } from './styles';

const Marker = ({
  positionRight, positionTop, text, nodot, modalType, body, image, noclick,
  additionalStyling
}) => {
  const dispatch = useDispatch();

  const modalBody = () => (
    <MDXProvider>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  );

  const dispatchModal = () => {
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: modalBody() });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: image });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: (isMobile ? 'mobile' : modalType) });
  };

  return (
    <>
      <MarkerWrapper
        positionRight={positionRight}
        positionTop={positionTop}
        role="button"
        tabindex="0"
        onClick={!noclick ? dispatchModal : null}
        noclick={noclick}
      >

        {text
          && <MarkerText style={additionalStyling}>{text}</MarkerText>}
        {!nodot && <DotIcon />}
      </MarkerWrapper>
    </>
  );
};
export default Marker;
