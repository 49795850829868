import React from 'react';
import { Explosion } from '../styles'
import img from 'src/assets/images/chapter_3/sub_5/_shared/explosion.svg';



const Animation = ({ top, right }) => {

    return (
        <>
            <Explosion
                style={{
                    top: `${top}%`,
                    right: `${right}%`,
                }}
                src={img}>
            </Explosion>
        </>
    )
};

export default Animation;
