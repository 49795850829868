import React, { useEffect, createRef, useState } from 'react';

import { MainAnimation, EmptyWrapper, Background, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation1 from './animations/4a.json';
import animation2 from './animations/4b.json';
import { SwitchTransition, CSSTransition } from "react-transition-group";


const Map1 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation1,
        });

        return () => anim.destroy();

    }, []);

    return (
        <EmptyWrapper>
            <MainAnimation ref={animationContainer}
            />
        </EmptyWrapper>
    )
}

const Map2 = ({ bckg }) => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation2,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <Background
                src={bckg}
                alt="map"
            />
            <MainAnimation ref={animationContainer} top={-3} right={-2} />
        </EmptyWrapper>
    )
}


const Map = ({ language }) => {
    const [mapIndex, setMapIndex] = useState(0);
    const bckg = require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-10b.svg`);

    const maps = [<Map1 />, <Map2 bckg={bckg} />]


    useEffect(() => {
        const interval = setInterval(() => {
            mapIndex < maps.length - 1 ? setMapIndex(index => index + 1) : setMapIndex(0);
        }, 5500);

        return () => clearInterval(interval);
    }, [mapIndex]);


    return (
        <OpacityWrapper>
            <SwitchTransition>
                <CSSTransition
                    key={mapIndex}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='fade-animation'
                >
                    <EmptyWrapper>
                        {maps[mapIndex]}
                    </EmptyWrapper>
                </CSSTransition>
            </SwitchTransition>
        </OpacityWrapper>
    )
};

export default Map;
