import React, { useState, useEffect } from 'react';

import {
  Wrapper, Line, Dot, RedLine, RedDot, DotContainer,
  ArrowDot, Arrow, AnimatedHoverCircle, OnSlideChangeAnimation,
  DateArrow, DateContainer, TitleContainer, GradientContainer,
  ToolTip,
} from './styles';

const Timeline = ({
  handleIndex,
  dots,
  yearsArray,
  titleArray,
  toolTipDesc,
}) => {
  const [red, setRed] = useState(100 / (dots + 1));
  const [activeIndex, setActiveIndex] = useState(0);
  const [oldIndex, setOldIndex] = useState(0);
  const [madeAnimationRight, setAnimationRight] = useState(false);
  const [madeAnimationLeft, setAnimationLeft] = useState(false);
  const [clickedArrow, setClickedArrow] = useState(false);

  const timeline = React.createRef();

  const setNewPosition = (index, vector) => {
    if (index >= 0 && index <= dots - 1) {
      if (vector === 'left') {
        setAnimationLeft(true);
      } else if (vector === 'right') {
        setAnimationRight(true);
      }
      if (!clickedArrow) {
        setClickedArrow(true);
      }
      const oneWidth = 100 / (dots + 1);
      const elementWidth = oneWidth * (index + 1);
      setRed(elementWidth);
      setOldIndex(activeIndex);
      setActiveIndex(index);
      handleIndex(index);
    }
  };
  const onAnimationEndLeft = () => {
    setAnimationLeft(false);
  };

  const onAnimationEndRight = () => {
    setAnimationRight(false);
  };
  const dotArray = [];
  for (let i = 0; i < dots; i++) {
    dotArray.push(i);
  }
  const allDots = dotArray.map((dot, index) => {
    const oneWidth = 100 / (dots + 1);
    const leftPosition = oneWidth * (index + 1);

    const style = {
      position: 'absolute',
      left: `${leftPosition}%`,
      cursor: `pointer`
    }
    let exception = null;
    yearsArray.forEach((item, i) => {
      if (item.index === index) {
        exception = item;
      }
    });
    let title = null;
    titleArray.forEach((item, i) => {
      if (item.index === index) {
        title = item;
      }
    });
    const titleStyle = {
      left: `${leftPosition + (0.5 * oneWidth)}%`,
      cursor: `pointer`
    }
    let showTitle = title ? <TitleContainer style={titleStyle} positionTop={title.position === "top"} positionBottom={title.position === "bottom"} > {title.text} </TitleContainer> : '';
    if (exception) {
      return (
        <div key={index}>
          {showTitle}
          <div style={style} onClick={() => { setNewPosition(index) }}>
            <DateArrow />
            <DateContainer>
              {exception.year}
            </DateContainer>
          </div>
        </div>
      )
    } else {
      return (
        <div key={index}>
          {showTitle}
          <DotContainer style={style} onClick={() => { setNewPosition(index) }}>
            <Dot />
            {(activeIndex >= index)
              && <RedDot
                widthElement={activeIndex === index && oldIndex - activeIndex < 0}
                showAll={index <= activeIndex}
              />}
          </DotContainer>
        </div>
      )
    }
  })

  return (
    <Wrapper>
      <GradientContainer />
      <Line ref={timeline}>
        <ArrowDot isLeft onClick={() => setNewPosition(activeIndex - 1, 'right')} isShowing={true} style={{ display: activeIndex === 0 ? 'none' : 'flex' }}>
          {(madeAnimationRight)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndRight} />}
          <AnimatedHoverCircle />
          <Arrow isLeft />
        </ArrowDot>
        <ArrowDot isRight onClick={() => setNewPosition(activeIndex + 1, 'left')} isShowing={true} style={{ display: activeIndex >= dots - 1 ? 'none' : 'flex' }}>
          <ToolTip clickedArrow={clickedArrow}>
            {toolTipDesc}
          </ToolTip>
          {(madeAnimationLeft)
            && <OnSlideChangeAnimation onAnimationEnd={onAnimationEndLeft} />}
          <AnimatedHoverCircle />
          <Arrow isRight />
        </ArrowDot>
        <RedLine redWidth={red} />
        {allDots}
      </Line>
    </Wrapper>
  )
};

export default Timeline;
