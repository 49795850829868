import React from 'react';
import Marker from 'src/components/desktop/marker/marker';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';

import Video from 'src/assets/videos/chapter_3/sub_5/slide-1-16.webm';
import VideoMP4 from 'src/assets/videos/chapter_3/sub_5/slide-1-16.mp4';
import { AssetsBackground } from './styles';

const NAMES_1 = {
  PL: 'Symon Petlura',
  EN: 'Symon Petliura',
  RU: 'Симон Петлюра',
  UA: 'Симон Петлюра',
  DE: 'Symon Petlura',
  FR: 'Symon Petlioura',
  HU: 'Szimon Petljura'
};

const NAMES_2 = {
  PL: 'Józef Piłsudski',
  RU: 'Юзеф Пилсудский',
  UA: 'Юзеф Пілсудський',
};
const Modals = ({ assets, modals, language }) => (
  <>
    <BackgroundAnimation src={Video} mp4={VideoMP4} />
    <Marker
      positionRight="51%"
      positionTop="35%"
      body={modals[0].body}
      text={NAMES_1[language] ? NAMES_1[language] : NAMES_1.PL}
      additionalStyling={{ textTransform: 'none', fontSize: '1.1rem' }}
    />
    <Marker
      positionRight={window.innerWidth >= 3000 ? '36%' : '27%'}
      positionTop="39%"
      body={modals[1].body}
      text={NAMES_2[language] ? NAMES_2[language] : NAMES_2.PL}
      additionalStyling={{ textTransform: 'none', fontSize: '1.1rem' }}
    />
  </>
);

export default Modals;
