import React from 'react';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';

import { OpacityWrapper } from './styles';

import Video from 'src/assets/videos/chapter_3/sub_5/slide-1-10.webm';
import VideoMP4 from 'src/assets/videos/chapter_3/sub_5/slide-1-10.mp4';


const Map = () => {

    return (
        <OpacityWrapper>
            <BackgroundAnimation src={Video} mp4={VideoMP4} />
        </OpacityWrapper>
    )
};

export default Map;
