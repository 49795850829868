import React, { useEffect, createRef } from 'react';
import { ArrowAnimation, OpacityWrapper } from './styles'
import img from 'src/assets/images/chapter_3/sub_5/faces/map-1.png';
import CircleImage from './elements/circleImage';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/arrow0.json';


const NAMES = {
    PL: 'Max Hoffman',
    RU: 'Макс Хоффман',
    UA: 'Макс Гофман'
}

const Map = ({ language }) => {
    const animationContainer = createRef();


    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            // path: '/assets/json/chapter_1/sub_3/data.json',
            animationData: animation,
        });
        setTimeout(() => { anim.play(); }, 750);
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    return (
        <OpacityWrapper initialAnimation={true}>
            <ArrowAnimation ref={animationContainer} style={{
                right: '44%',
                top: '36%',
                width: '30%'
            }} />
            <CircleImage src={img} name={NAMES[language] ? NAMES[language] : NAMES['PL']} top={34} right={39} />
        </OpacityWrapper>
    )
};

export default Map;
