import React from 'react';
import { useSelector } from 'react-redux';
import { LegendWrapper, LegendContentWrapper, LegendTitle, LegendRect, LegendRow, LegendImg, LegendContent } from '../styles'
import dashedBlue from 'src/assets/images/chapter_3/sub_5/_shared/legend-dashed-blue.svg';
import arrowBlue from 'src/assets/images/chapter_3/sub_5/_shared/legend-arrow-blue.svg';
import dashedRed from 'src/assets/images/chapter_3/sub_5/_shared/legend-dashed-red.svg';
import arrowRed from 'src/assets/images/chapter_3/sub_5/_shared/legend-arrow-red.svg';
import arrowPurple from 'src/assets/images/chapter_3/sub_5/_shared/legend-arrow-purple.svg';
import { getLangFromPath } from 'src/utils/index.js';

const content0 = {
    PL: ['Pozycje polskie', 'Ruchy Polaków', 'Pozycje bolszewików', 'Ruchy bolszewików'],
    EN: ['Polish positions', 'Polish movements', 'Bolshevik positions', 'Bolshevik movements'],
    FR: ['Positions polonaises', 'Mouvements polonais', 'Positions bolcheviques', 'Mouvements bolcheviques'],
    UA: ['Польські позиції', 'Пересування поляків', 'Більшовистські позиції', 'Пересування більшовиків'],
    RU: ['Польские позиции', 'Передвижения поляков', 'Большевистские позиции', 'Передвижения большевиков'],
    DE: ['Polnische Positionen', 'Die Bewegungen der Polen', 'Bolschewistische Positionen', 'Die Bewegungen der Bolschewiki'],
    HU: ['Lengyel pozíciók', 'A lengyelek hadmozdulatai', 'Bolsevista pozíciók', 'A bolsevisták hadmozdulatai'],
};

const content1 = {
    PL: ['Ruchy Łotyszy', 'Ruchy Polaków', 'Pozycje bolszewików', 'Ruchy bolszewików'],
    EN: ['Latvian movements', 'Polish movements', 'Bolshevik positions', 'Bolshevik movements'],
    FR: ['Mouvements lettons', 'Mouvements polonais', 'Positions bolcheviques', 'Mouvements bolcheviques'],
    UA: ['Пересування латишів', 'Пересування поляків', 'Більшовистські позиції', 'Пересування більшовиків'],
    RU: ['Передвижения латышей', 'Передвижения поляков', 'Большевистские позиции', 'Передвижения большевиков'],
    DE: ['Die Bewegungen der Letten', 'Die Bewegungen der Polen', 'Bolschewistische Positionen', 'Die Bewegungen der Bolschewiki'],
    HU: ['A lettek hadmozdulatai', 'A lengyelek hadmozdulatai', 'Bolsevista pozíciók', 'A bolsevisták hadmozdulatai'],
};

const content2 = {
    PL: ['Tereny zajęte przez Armię Czerwoną do:', 'połowy czerwca 1920', 'końca czerwca 1920', 'połowy lipca 1920', 'końca lipca 1920', 'Bitwy Warszawskiej'],
    EN: ['Areas occupied by the Red Army by:', ' Mid-June 1920', 'End of June 1920', 'Mid-July 1920', 'End of July 1920', 'Of the Battle of Warsaw'],
    FR: ['Zones occupées par l\'Armée rouge en :', 'Mi juin 1920', 'Fin juin 1920', 'Mi-juillet 1920', 'Fin juillet 1920', 'Bataille de Varsovie'],
    UA: ['Території, зайняті Червоною Армією до:', 'Середини червня 1920 року', 'Кінця червня 1920 року', 'Середини липня 1920 року', 'Кінця липня 1920 року', 'Варшавської битви'],
    RU: ['Территории, занятые Красной Армией до:', 'Середины июня 1920 года', 'Конца июня 1920 года', 'Середины июля 1920 года', 'Конца июля 1920 года', 'Варшавской битвы'],
    DE: ['Die durch die Rote Armee besetzten Gebiete bis:', 'Mitte Juni 1920', 'Ende Juni 1920', 'Mitte Juli 1920', 'Ende Juli 1920', 'Schlacht bei Warschau'],
    HU: ['A Vörös Hadsereg által elfoglalt területek:', '1920. június közepéig', '1920. június végéig', '1920. július közepéig', '1920. július végéig', 'A Varsói Csatáig'],
};

const LEGENDS = [content0, content1, content2];

const Legend = ({ legendType = 0 }) => {
    const language = getLangFromPath();


    return (
        <LegendWrapper>
            <LegendContentWrapper>
                {legendType !== 2 ?
                    <>
                        <LegendRow>
                            <LegendImg src={legendType === 0 ? dashedBlue : arrowPurple} />
                            <LegendContent>
                                {LEGENDS[legendType][language][0]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendImg src={arrowBlue} />
                            <LegendContent>
                                {LEGENDS[legendType][language][1]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendImg src={dashedRed} />
                            <LegendContent>
                                {LEGENDS[legendType][language][2]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendImg src={arrowRed} />
                            <LegendContent>
                                {LEGENDS[legendType][language][3]}
                            </LegendContent>
                        </LegendRow>
                    </>
                    :
                    <>
                        <LegendTitle>
                            {LEGENDS[legendType][language][0]}
                        </LegendTitle>
                        <LegendRow>
                            <LegendRect color="#70161F" />
                            <LegendContent>
                                {LEGENDS[legendType][language][1]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendRect color="#871B2B" />
                            <LegendContent>
                                {LEGENDS[legendType][language][2]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendRect color="#A0223A" />
                            <LegendContent>
                                {LEGENDS[legendType][language][3]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendRect color="#B5243C" />
                            <LegendContent>
                                {LEGENDS[legendType][language][4]}
                            </LegendContent>
                        </LegendRow>
                        <LegendRow>
                            <LegendRect color="#C62242" />
                            <LegendContent>
                                {LEGENDS[legendType][language][5]}
                            </LegendContent>
                        </LegendRow>

                    </>
                }
            </LegendContentWrapper>
        </LegendWrapper>
    )
};

export default Legend;
