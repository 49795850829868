/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import {
  BlockContainer,
  Arrow,
  ArrowSliderLeft,
  ArrowSliderRight,
} from '../../_styles';
import Timeline from '../../../../../components/mobile/timeline/timeline';

const CHAPTERS = {
  0: [0, 1, 2, 3],
  4: [4, 5, 6],
  7: [7, 8, 9, 10],
  11: [11, 12, 13, 14],
  15: [15, 16],
  17: [17],
  18: [18],
  19: [19],
};

const yearsArray = [
  {
    index: 0,
    year: '1918',
  },
  {
    index: 3,
    year: '1919',
  },
  {
    index: 18,
    year: '1920',
  },
];

const ModalSlider = ({ query, slides }) => {
  const [contentIndex, setContentIndex] = useState(0);
  const [slider, setSlider] = useState(null);
  const [currentChapter, setCurrentChapter] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    afterChange: (index) => setActiveIndex(index),
    onReInit: () => slider.slickGoTo(activeIndex),
  };

  const handleIndex = (ix) => {
    if (ix === contentIndex) return;
    setContentIndex(ix);
    for (const value in CHAPTERS) {
      if (CHAPTERS[value].some((el) => el === ix)) {
        value !== currentChapter && setCurrentChapter(+value);
      }
    }
  };
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <Slider ref={(slider) => setSlider(slider)} {...settings}>
        {slides}
      </Slider>
      <Timeline
        index={1}
        yearsArray={yearsArray}
        titleArray={query.timelineTitels.exports.titleArray}
        handleIndex={handleIndex}
        dots={19}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        translateX={17 + activeIndex * 3.66}
      />
      <ArrowSliderLeft onClick={() => setActiveIndex(activeIndex - 1)}>
        <Arrow reverse />
      </ArrowSliderLeft>
      <ArrowSliderRight onClick={() => setActiveIndex(activeIndex + 1)}>
        <Arrow />
      </ArrowSliderRight>
    </div>
  );
};

export default ModalSlider;
