import React, { useEffect, createRef, useState } from 'react';
import ExplosionAnimation from './elements/explosion';
import Cannon from './elements/cannon';
import Legend from './elements/legend';
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { MainAnimation, EmptyWrapper, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation1 from './animations/1d2.json';
import animation2 from './animations/1d3.json';
import animation3 from './animations/1d4.json';

const Map1 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation1,
        });

        return () => anim.destroy();

    }, []);

    return (
        <EmptyWrapper>
            <MainAnimation ref={animationContainer}
                top={3} />
            <ExplosionAnimation top={44} right={71} />
            <ExplosionAnimation top={16} right={33} />
            <ExplosionAnimation top={54} right={34} />
        </EmptyWrapper>
    )
}

const Map2 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation2,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <MainAnimation ref={animationContainer}
                top={3} right={1} />
            <ExplosionAnimation top={50} right={44} />
            <ExplosionAnimation top={51} right={48} />
            <Cannon top={38} right={44} />
        </EmptyWrapper>
    )
}

const Map3 = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation3,
        });

        return () => anim.destroy();

    }, []);
    return (
        <EmptyWrapper >
            <MainAnimation ref={animationContainer}
                top={3} right={1} />
            <ExplosionAnimation top={50} right={44} />
            <ExplosionAnimation top={51} right={48} />
            <Cannon top={38} right={44} />
        </EmptyWrapper>
    )
}

const Map = () => {
    const [mapIndex, setMapIndex] = useState(0);
    const maps = [<Map1 />, <Map2 />, <Map3 />]

    useEffect(() => {
        const interval = setInterval(() => {
            mapIndex < maps.length - 1 ? setMapIndex(index => index + 1) : setMapIndex(0);
        }, 5500);

        return () => clearInterval(interval);
    }, [mapIndex]);


    return (
        <OpacityWrapper>
            <SwitchTransition>
                <CSSTransition
                    key={mapIndex}
                    addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                    classNames='fade-animation'
                >
                    <EmptyWrapper>
                        {maps[mapIndex]}
                    </EmptyWrapper>
                </CSSTransition>
            </SwitchTransition>
            {/* <Legend /> */}
        </OpacityWrapper>
    )
};

export default Map;
