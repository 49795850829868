import React, { useEffect, createRef } from 'react';
import Legend from './elements/legend';


const Map = () => {
    return (
        <>
            {/* <Legend legendType={2} /> */}
        </>
    )
};

export default Map;
