import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Wrapper, XXXZZZ } from "./styles"
import Description from "../description/description"
import Map from "../map/map"
import Timeline from "../timeline/timeline"
import InitialView from "../initialView/initialView"
import { motion, AnimatePresence } from "framer-motion"
import actions from "src/store/actions"

const CHAPTERS = {
  0: [0, 1, 2, 3],
  4: [4, 5, 6],
  7: [7, 8, 9, 10],
  11: [11, 12, 13, 14],
  15: [15, 16],
  17: [17],
  18: [18],
  19: [19],
}

const yearsArray = [
  {
    index: 0,
    year: "1918",
  },
  {
    index: 3,
    year: "1919",
  },
  {
    index: 13,
    year: "1920",
  },
]

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
}

const MainWrapper = ({
  data,
  assets,
  modals,
  quote,
  titles,
  initialScreen,
  theme,
}) => {
  const [initialScreenVisible, setInitialScreenVisible] = useState(true)
  const [mapsScreenVisible, setMapsScreenVisible] = useState(false)
  const dataArray = data
  const [contentIndex, setContentIndex] = useState(0)
  const [currentChapter, setCurrentChapter] = useState(0)
  const dispatch = useDispatch()

  const handleIndex = ix => {
    if (ix === contentIndex) return
    setContentIndex(ix)
    for (let value in CHAPTERS) {
      if (CHAPTERS[value].some(el => el === ix)) {
        value !== currentChapter && setCurrentChapter(+value)
      }
    }
  }

  useEffect(() => {
    if (mapsScreenVisible) {
      dispatch({ type: actions.SET_SLIDER_THEME, payload: theme })
    }
    return () => {
      dispatch({ type: actions.SET_SLIDER_THEME, payload: null })
    }
  }, [mapsScreenVisible])

  const hideInitialScreen = () => {
    setInitialScreenVisible(false)
  }

  return (
    <>
      <AnimatePresence
        initial={false}
        onExitComplete={() => setMapsScreenVisible(true)}
      >
        {initialScreenVisible && (
          <motion.div
            key={initialScreenVisible}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <InitialView
              data={initialScreen}
              assets={assets}
              hideInitialScreen={hideInitialScreen}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {mapsScreenVisible && (
        <Wrapper>
          <Description
            dataArray={dataArray}
            contentIndex={contentIndex}
            currentChapter={currentChapter}
            assets={assets}
          />
          <Map
            index={contentIndex}
            assets={assets}
            modals={modals}
            quote={quote}
          />
          <Timeline
            index={contentIndex}
            yearsArray={yearsArray}
            titleArray={titles.exports.titleArray}
            toolTipDesc={titles.frontmatter.toolTip}
            handleIndex={handleIndex}
            dots={19}
          />
        </Wrapper>
      )}
    </>
  )
}

export default MainWrapper
