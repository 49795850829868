import React, { useEffect, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils/index.js';
import {
  Wrapper, Content, Header, Text,
} from './styles';
import { mediaServer } from '../../../../../globals';

const MDXWrapper = ({ children, language, assets }) => (
  <MDXProvider components={{
    Hyperlink,
    AudioPlayer,
  }}
  >
    <MDXRenderer
      audioImage1={assets.audioImage_1.childImageSharp.fluid}
      audioImage2={assets.audioImage_2.childImageSharp.fluid}
      audioImage3={assets.audioImage_3.childImageSharp.fluid}
      audioImage4={assets.audioImage_4.childImageSharp.fluid}
      audio1={[`${mediaServer}/webm/${language.toLowerCase()}/359kopanski.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/359kopanski.mp4`]}
      audio2={[`${mediaServer}/webm/${language.toLowerCase()}/3515iranek.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/3515iranek.mp4`]}
      audio3={[`${mediaServer}/webm/${language.toLowerCase()}/3516jedrzejewicz.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/3516jedrzejewicz.mp4`]}
      audio4={[`${mediaServer}/webm/${language.toLowerCase()}/3517krzeczunowicz.webm`, `${mediaServer}/mp4/${language.toLowerCase()}/3517krzeczunowicz.mp4`]}
      modal1={assets.modalImage1.childImageSharp.fixed}
    >
      {children}
    </MDXRenderer>
  </MDXProvider>
)

const setStyles = (ix, header, currentChapter, contentIndex) => {
  if (header && currentChapter === ix && ix < contentIndex) {
    return {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1,
    };
  }

  if (ix === contentIndex) {
    return {
      transform: 'translate3d(0, 0, 0)',
      opacity: 1,
    };
  }

  if (ix < contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(-200%, 0, 0)',
      opacity: ix < contentIndex ? 0 : 1,
    };
  }

  if (ix > contentIndex) {
    return {
      position: 'absolute',
      transform: 'translate3d(200%, 0, 0)',
      opacity: ix > contentIndex ? 0 : 1,
    };
  }
};

const Description = ({
  dataArray, contentIndex, currentChapter, assets,
}) => {
  const [initialAnimation, setInitialAnimation] = useState(true);
  const language = getLangFromPath();

  useEffect(() => {
    contentIndex > 0 && setInitialAnimation(false);
  }, [contentIndex]);


  return (
    <Wrapper>
      <Content animate={initialAnimation}>
        {dataArray.map((el, ix) => (
          <Header
            key={ix}
            style={setStyles(ix, true, currentChapter, contentIndex)}
          >
            <MDXWrapper language={language} assets={assets}>
              {el.node.body}
            </MDXWrapper>
          </Header>
        ))}
        {dataArray.map((el, ix) => (
          <Text
            key={ix}
            style={setStyles(ix, false, currentChapter, contentIndex)}
          >
            <MDXWrapper language={language} assets={assets}>
              {el.node.body}
            </MDXWrapper>
          </Text>
        ))}
      </Content>
    </Wrapper>
  );
};

export default React.memo(Description);
