import styled, { keyframes, css } from 'styled-components';
import { fonts, colors } from '../../../../../../globals';


const scaleAnimation = keyframes`
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
`;

const opacityAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const textureAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.1;
  }
`;


export const Wrapper = styled.div`
    flex: 1;
    background: inherit;
    z-index: 2;
    position: relative;
    overflow: hidden;
    background: #272323;
    display: flex;
    align-items: center;
`;


export const MapContainer = styled.div`
    ${({ fixedSize }) => fixedSize
    && css`
    position: absolute;
    width: 1336px;
    height: 970px;
    right: -1vw;        
    `}
    
    & p {
      ${({ scaleDown }) => `
        transform: scale(${scaleDown}) !important;
        margin-top: ${(scaleDown * 0.8) * 8}px;
    `}
    }

    transform-origin: right center;
    overflow: hidden;

    .fade-map-enter{
   opacity: 0;
    }
    .fade-map-exit{
    opacity: 1;
    }
    .fade-map-enter-active{
    opacity: 1;
    }
    .fade-map-exit-active{
    opacity: 0;
    }
    .fade-map-enter-active {
    transition: opacity 1.5s;    
    }
    .fade-map-exit-active{
    transition: opacity 1.5s;
    }

    .fade-animation-enter{
    opacity: 0;
    }
    .fade-animation-exit{
    opacity: 1;
    }
    .fade-animation-enter-active{
    opacity: 1;
    }
    .fade-animation-exit-active{
    opacity: 0;
    }
    .fade-animation-enter-active {
    transition: opacity 1s;    
    }
    .fade-animation-exit-active{
    transition: opacity 1s;
    }

`;
export const Background = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
    ${({ animate }) => animate
    && css`
        transform: scale(1.2);
        animation: ${scaleAnimation} 1s ease forwards,
        ${opacityAnim} 2s forwards;
        `}
`;
export const Texture = styled(Background)`
    opacity: 0.1;
    ${({ animate }) => animate
    && css`
        animation: ${textureAnim} 1s ease forwards;
        `}
`;


export const Date = styled.div`
    ${{ ...fonts.headline_1 }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: absolute;
    top: 2rem;
    right: 2rem;
`;
export const CircleImageWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CircleImg = styled.img`
    background: #181f26;
    border-radius: 50%;
    padding: 3px;
    box-shadow: 20px 21px 18px rgba(24, 31, 38, 0.27);
`;


export const CircleFlag = styled.img`
    position: absolute;
    border-radius: 50%;
    box-shadow: 20px 21px 18px rgba(24, 31, 38, 0.27);
    width: 2.7rem;
    ${({ top, right }) => `
        top: ${top}%;
        right: ${right}%;
    `}
`;


export const CircleImageTitle = styled.p`
    ${{ ...fonts.paragraf }}
    /* font-size: 16px; */
    line-height: 24px;
    ${{ ...colors.light }}
    margin-bottom: 0;
    font-weight: 700;
    border-radius: 2px;
    letter-spacing: 1px;
    background-color: #2b2425;
    padding: 2px 6px;
    text-align: center;
    line-height: 1.5rem;
    /* margin-top: 8px; */
`;

export const MainAnimation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ top, right }) => `
        top: ${top}%;
        right: ${right}%;
    `}
`;

export const ArrowAnimation = styled.div`
  position: absolute;
`;

const explosionEnter = keyframes`
  from {
    transform: scale(0)  
    }
  to {
    transform: scale(1) 
    }
`;


const explosionAnimate = keyframes`
  from {
    transform: scale(1)  
    }
  to {
    transform: scale(0.7) 
    }
`;

export const Explosion = styled.img`
  position: absolute;
  transform-origin: bottom;
  width: 5%;
  /* height: 34px; */
  transform: scale(0);
  animation: ${explosionEnter} 500ms ease-out 200ms, 
  ${explosionAnimate} 700ms ease-in-out 700ms infinite alternate;
`;
// EmptyWrapper potrzebny dla opakowania w diva dla react transitions. inaczej nie działają

export const EmptyWrapper = styled.div`
`;

export const OpacityWrapper = styled.div`
  opacity: 0;
  animation: ${opacityAnim} .8s forwards;
  animation-delay: .3s;
  ${({ initialAnimation }) => initialAnimation
    && css`
        animation-delay: .5s;
        `}
`;

export const CannonImg = styled.img`
  position: absolute;
  width: 4%;
  box-shadow: 10px 10px 31px 0px rgba(0,0,0,0.15);
  border-radius: 50%;
`;

export const LegendWrapper = styled.div`
  position: fixed;
  bottom: 17%;
  right: 2vw;
  background-color: #342d2e;
  padding: 5px;
  border-radius: 3px;
  opacity: 0;
  animation: ${opacityAnim} .8s forwards;
`;

export const LegendContentWrapper = styled.div`
  border: 1px solid rgba(255,255,255,0.2);
  padding: 5px 15px;
  border-radius: 3px;
`;

export const LegendTitle = styled.h4`
    ${{ ...fonts.map_legend_title }}
    ${{ ...colors.light }}
    max-width: 10rem;
    margin-right: 3rem;
`;

export const LegendRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;

export const LegendRect = styled.div`
  width: 18%;
  height: 0.8rem;
  margin-right: 1.5rem;
  ${({ color }) => `
        background: ${color};
    `}
`;

export const LegendImg = styled.img`
  width: 2.5rem;
  margin-right: 15px;
`;

export const LegendContent = styled.p`
    ${{ ...fonts.map_legend }}
    ${{ ...colors.light }}
`;


export const AssetsBackground = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
`;
