import React, { useState, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Wrapper, MapContainer, Background, Date, EmptyWrapper, Texture } from './styles';
import { Preload } from 'react-preload';
import texture from 'src/assets/images/chapter_3/sub_5/_shared/texture.png';
import Legend from './elements/legend';
import { getLangFromPath } from 'src/utils/index.js'

import SubMap1 from './slide1';
import SubMap2 from './slide2';
import SubMap3 from './slide3';
import SubMap4 from './slide4';
import SubMap5 from './slide5';
import SubMap6 from './slide6';
import SubMap7 from './slide7';
import SubMap8 from './slide8';
import SubMap9 from './slide9';
import Video from './slide10';
import SubMap10 from './slide11';
import SubMap11 from './slide12';
import SubMap12 from './slide13';
import SubMap13 from './slide14';
import SubMap14 from './slide15';
import Modals from './slide16';
import SubMap15 from './slide17';
import Quote from './slide18';
import SubMap16 from './slide19';

const loadLegend = (index) => {
    switch (index) {
        case 3:
        case 8:
            return <Legend />
        case 13:
        case 14:
            return <Legend legendType={1} />
        case 18:
            return <Legend legendType={2} />
        default: return null;
    }
}

const Map = ({ index, assets, modals, quote }) => {
    const language = getLangFromPath();

    const BACKGROUNDS = {
        0: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-0.svg`),
        1: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-1.svg`),
        2: assets[`img_3_5_2`].childImageSharp.fluid.src,
        3: assets[`img_3_5_3`].childImageSharp.fluid.src,
        4: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-4.svg`),
        5: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-5.svg`),
        6: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-6.svg`),
        7: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-7.svg`),
        8: assets[`img_3_5_8`].childImageSharp.fluid.src,
        9: require(`../../../../assets/images/chapter_3/sub_5/3-5-17.svg`),// fake bckg
        10: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-10.svg`),
        11: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-11.svg`),
        12: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-12.svg`),
        13: assets[`img_3_5_13`].childImageSharp.fluid.src,
        14: assets[`img_3_5_14`].childImageSharp.fluid.src,
        15: require(`../../../../assets/images/chapter_3/sub_5/3-5-17.svg`), // fake bckg
        16: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-16.svg`),
        17: require(`../../../../assets/images/chapter_3/sub_5/3-5-17.svg`), // fake bckg
        18: require(`../../../../assets/images/chapter_3/sub_5/${language}/3-5-18.svg`),
    }

    const windowDimentions = window.innerWidth / 1920 * 1.1;

    const noBackground = 17; // slide with paralax
    const [date, setDate] = useState();
    const [mapScale, setMapScale] = useState(windowDimentions);
    const [initialAnimation, setInitialAnimation] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    window.addEventListener('resize', () => {
        setMapScale(window.innerWidth / 1920 * 1.05);
    })

    const dates = {
        1918: [0],
        1919: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        1920: [13, 14, 15, 16, 17, 18]
    }

    const SLIDES = [<SubMap1 language={language} />, <SubMap2 />,
    <SubMap3 language={language} />, <SubMap4 />,
    <SubMap5 />, <SubMap6 />,
    <SubMap7 />, <SubMap8 />,
    <SubMap9 language={language} />, <Video />,
    <SubMap10 language={language} />, <SubMap11 language={language} />,
    <SubMap12 language={language} />, <SubMap13 language={language} />,
    <SubMap14 language={language} />, <Modals assets={assets} modals={modals} language={language} />,
    <SubMap15 language={language} />, <Quote quote={quote} assets={assets} />,
    <SubMap16 />]

    useEffect(() => {
        for (let value in dates) {
            if (dates[value].some(el => el == index)) {
                value !== date && setDate(value);
            }
        }
        index > 0 && setInitialAnimation(false)
    }, [index]);

    useEffect(() => {
        setImageLoaded(false);
        index === 17 && setImageLoaded(true);
        index === 9 && setImageLoaded(true);
    }, [index])

    const handleOnLoad = () => {
        setImageLoaded(true);
    }
    const preloadImages = Object.values(BACKGROUNDS);
    return (
        <Wrapper >
            <Preload
                images={preloadImages}
                autoResolveDelay={5000}
                resolveOnError={true}
                mountChildren={true}
            >
                <>
                    <MapContainer
                        style={{ transform: index !== noBackground && `scale(${mapScale})` }}
                        fixedSize={index !== noBackground}
                        scaleDown={index !== noBackground ? 1 / mapScale : 1}
                    >
                        {index !== noBackground && <Background
                            key={index}
                            src={BACKGROUNDS[index]}
                            alt="map"
                            index={index}
                            onLoad={handleOnLoad}
                            animate={initialAnimation}
                        />}
                        <SwitchTransition>
                            <CSSTransition
                                key={index}
                                addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                classNames='fade-map'
                            >
                                <EmptyWrapper>
                                    {index !== noBackground &&
                                        <Background
                                            animate={initialAnimation}
                                            key={index}
                                            src={BACKGROUNDS[index]}
                                            alt="map"
                                        />}
                                </EmptyWrapper>
                            </CSSTransition>
                        </SwitchTransition>
                        {imageLoaded && SLIDES[index]}
                    </MapContainer>
                    {loadLegend(index)}
                    <Date>
                        {date}
                    </Date>
                    {index !== 15 && index !== 17 &&
                        <Texture src={texture} animate={initialAnimation}
                        />}
                </>
            </Preload>
        </Wrapper>
    )
};

export default React.memo(Map);
