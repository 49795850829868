import React, { useState } from 'react';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import ModalSlider from './ModalSlider';
import {
  BlockContainer,
  ButtonWithBackground,
  Arrow,
  ChpaterTitle,
} from '../../_styles';

const Slide = ({
  query, title, slides, buttonText,
}) => {
  const dispatch = useDispatch();
  const dispatchModal = () => {
    dispatch({
      type: actions.SET_MODAL_CONTENTS,
      payload: <ModalSlider query={query} slides={slides} />,
    });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: null });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'mobile' });
  };
  return (
    <BlockContainer height="900px">
      {/* <BackgroundAnimation> */}
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        scale
        alt="slide-photo"
      >
        <ChpaterTitle dark marginTop="8rem">{title}</ChpaterTitle>
        <TextAnimation
          body={query.mdx.body}
          containerStyle={{ height: '40%' }}
          dark
        />
        <ButtonWithBackground onClick={() => dispatchModal()} style={{ marginTop: '2rem' }}>
          {buttonText}
        </ButtonWithBackground>
      </BackgroundPhoto>
      {/* </BackgroundAnimation>  */}
    </BlockContainer>
  );
};

export default Slide;
