import React, { useEffect, createRef } from 'react';
import { MainAnimation, OpacityWrapper } from './styles';
import lottie from 'lottie-web/build/player/lottie_svg';
import animation from './animations/2b.json';
import ExplosionAnimation from './elements/explosion';


const Map = () => {
    const animationContainer = createRef();

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animation,
        });
        return () => anim.destroy();
    }, []);

    return (
        <OpacityWrapper>
            <MainAnimation ref={animationContainer} />
            <ExplosionAnimation top={34} right={38} />
        </OpacityWrapper>
    )
};

export default Map;
